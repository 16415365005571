.Jumbo {
  background-image: url(./Images/Jumbo2.jpg);

  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  z-index: 1;
  position: relative;
  border-radius: 0 0 10px 10px;

  .JumboContent {
    color: white;
    text-align: center;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;

    h1 {
      padding-block-start: 35vh;
      font-size: 6em;
      font-weight: 700;
      margin-top: 0;
      -webkit-text-stroke: 2px black;
    }

    p {
      font-size: 2em;
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 1em;
      margin-bottom: 1em;
      background-color: rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.4);
    }
  }
}

h2.Countdown {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.page#Home {
  margin-top: 0;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .JumboContent h1 {
    font-size: 5em;
    font-weight: 500;
  }
  .JumboContent p {
    font-size: 2em;
  }
}
