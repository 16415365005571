@import "_common.scss";

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  body {
    font-size: 13px;
  }
}

body {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(./Roses_pattern011.jpg);
  background-size: 768px;
}
.Content {
  background-color: rgba(255, 255, 255, 0.8);
  width: unquote("min(95vw, 768px)");
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;

  .ContentBlock {
    padding-bottom: 50px;
  }

  h1 {
    text-align: center;
    font-size: 2em;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 0.5em;
    align-items: center;

    &::after,
    &::before {
      content: "";
      background-image: url(./Leaves311.png);
      background-size: 3em 1em;
      height: 1em;
      width: 3em;
    }
    &::before {
      transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      justify-self: right;
    }
  }

  h2 {
    text-align: center;
  }
  h3 {
    margin-left: 2em;
    font-size: 1.5em;
  }

  p {
    font-size: 1.5em;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

button {
  color: white;
  background-color: $theme_color;
  border-radius: 25px;
  padding: 10px 1.3em;
  margin: 10px 0.5em;
  border: none;
  box-shadow: none;
  font-size: 1.6em;

  &:hover {
    box-shadow: 0px 0px 20px 3px $theme_color_soft;
  }

  &:disabled {
    background-color: gray;
    box-shadow: none;
  }
}

:focus {
  outline: none;
}
