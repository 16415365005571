@import "../_common.scss";

.bottomBar {
  bottom: 0;
  text-align: center;
  font-size: 1.2em;
  width: 100;
  background-color: rgb(255, 238, 245);
  border-top-style: solid;
  border-top-width: 3px;
  border-top-color: $theme_color;
}
